import { FC, Fragment, useState, useEffect } from 'react';
import { Menu } from 'react-feather';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../../common/Logo/Index';
import useWindowSize from '../../../utility/hooks/useWindowSize';
import { HashLink } from 'react-router-hash-link';

import './Navbar.scss';

interface NavItemInterface {
  label: string;
  hashTo: string;
}

const NAV_ITEMS: Array<NavItemInterface> = [
  {
    label: 'หน้าแรก',
    hashTo: 'home#',
  },
  {
    label: 'เกี่ยวกับเรา',
    hashTo: 'home#about_us',
  },
  {
    label: 'ราคา',
    hashTo: 'home#package',
  },
  {
    label: 'บริการ',
    hashTo: 'home#services',
  },
  {
    label: 'ติดต่อเรา',
    hashTo: 'home#contact_us',
  },
];

const Navbar: FC = () => {
  const [isScrolling, setScrolling] = useState(false);
  const { width } = useWindowSize();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setScrolling(currentScrollPos >= 45);
  };

  useEffect(() => {
    if (!window) return;

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [typeof window, handleScroll]);

  return (
    <header
      className={`app-navbar ${isScrolling ? 'scrolling shadow-sm' : ''}`}
    >
      <div className='container-lg d-flex justify-content-between'>
        <Logo width={isScrolling ? 90 : 136} />
        {width > 992 ? (
          <Fragment>
            <ul className='app-nav'>
              {NAV_ITEMS.map((nav) => (
                <li key={nav.label}>
                  <HashLink smooth to={nav.hashTo}>
                    {nav.label}
                  </HashLink>
                </li>
              ))}
            </ul>
            <div className='action-button-group'>
              <HashLink smooth to='/register#'>
                <Button
                  outline
                  color='primary'
                  className='rounded-pill'
                  size={isScrolling ? 'sm' : 'md'}
                >
                  ลงทะเบียน
                </Button>
              </HashLink>
              <Link to='/login'>
                <Button
                  color='primary'
                  className='rounded-pill'
                  size={isScrolling ? 'sm' : 'md'}
                >
                  เข้าสู่ระบบ
                </Button>
              </Link>
            </div>
          </Fragment>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};
export default Navbar;
