import WrappedInput from '../../common/Input/WrappedInput';

import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { REQUIRED_FIELD } from '../../../utility/constants';
import useAlert from '../../../utility/hooks/useAlert';
import authServices from '../../../services/auth';
import WrappedPasswordInput from '../../../components/common/Input/WrappedPasswordInput';

const Title = styled.p`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
`;
const SubTitle = styled.p`
  font-size: 14px;
  text-align: center;
  color: #6e6b7b;
`;
const ActionButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  margin-top: 32px;
`;
const schema = Yup.object().shape({
  oldPassword: Yup.string().required(REQUIRED_FIELD),
  newPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .min(8, 'รหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป'),
  repeatPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .oneOf([Yup.ref('newPassword'), null], 'รหัสผ่านไม่ตรงกัน')
    .min(8, 'รหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป'),
});

interface ChangePasswordInterface {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}
interface NewPasswordProps extends ModalProps {
  onSubmited?: () => void;
}

const ChangePassowordModal: FC<NewPasswordProps> = ({
  onSubmited,
  isOpen,
  onClosed,
}) => {
  const { showErrorMessage, showSuccessMessage } = useAlert();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordInterface>({
    resolver: yupResolver(schema),
  });
  const onSubmitHandle: SubmitHandler<ChangePasswordInterface> = async (
    data
  ) => {
    try {
      const { status } = await authServices.changePassword(data);
      if (status === 200) {
        onSubmited?.();
        showSuccessMessage('เปลี่ยนรหัสผ่านสำเร็จ');
      }
    } catch (error: any) {
      switch (error.response.status) {
        case 404:
          showErrorMessage('รหัสผ่านเดิมไม่ถูกต้อง');
          reset();
          break;
        default:
          showErrorMessage(error);
          break;
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClosed={onClosed}
        className='modal-dialog-centered modal-lg'
      >
        <ModalHeader toggle={onClosed} className='bg-white' />
        <ModalBody className='px-5 pb-5'>
          <Title>เปลี่ยนรหัสผ่าน</Title>
          <SubTitle>แก้ไขรหัสผ่านสำหรับเข้าใช้งานระบบ</SubTitle>
          <Form
            className='auth-forgot-password-form mt-2'
            onSubmit={handleSubmit(onSubmitHandle)}
          >
            <div className='my-1'>
              <Label className='form-label' for='password'>
                รหัสผ่านเดิม <span className='text-danger'>*</span>
              </Label>
              <WrappedPasswordInput
                id='password'
                placeholder='กรอกรหัสผ่าน'
                className='input-group-merge'
                invalid={!!errors?.oldPassword}
                {...register('oldPassword')}
              />
              <FormFeedback>{errors.oldPassword?.message}</FormFeedback>
            </div>
            <div className=''>
              <Label className='form-label' for='password'>
                รหัสผ่านใหม่ <span className='text-danger'>*</span>
              </Label>
              <WrappedPasswordInput
                id='newPassword'
                placeholder='กรอกรหัสผ่าน'
                className='input-group-merge'
                invalid={!!errors?.newPassword}
                {...register('newPassword')}
              />{' '}
              <FormFeedback>{errors.newPassword?.message}</FormFeedback>
            </div>
            <div className='mt-1'>
              <Label className='form-label' for='password'>
                ยืนยันรหัสผ่าน <span className='text-danger'>*</span>
              </Label>
              <WrappedPasswordInput
                id='repeatPassword'
                placeholder='ยืนยันรหัสผ่าน'
                className='input-group-merge'
                invalid={!!errors?.repeatPassword}
                {...register('repeatPassword')}
              />
              <FormFeedback>{errors.repeatPassword?.message}</FormFeedback>
            </div>
            <ActionButtonGroup>
              <Button
                type='submit'
                color='primary'
                className='mt-1'
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size='sm' />}
                <span
                  className={`${
                    isSubmitting ? 'ms-50 pointer-events-none opacity-50' : ''
                  }`}
                >
                  {isSubmitting ? 'กำลังเปลี่ยนรหัสผ่าน...' : 'เปลี่ยนรหัสผ่าน'}
                </span>
              </Button>
              <Button
                type='reset'
                color='dark'
                outline
                className='mt-1'
                onClick={onClosed}
              >
                ยกเลิก
              </Button>
            </ActionButtonGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ChangePassowordModal;
