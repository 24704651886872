// ** Icons Import
import {
  PieChart,
  Calendar,
  Compass,
  FileText,
  Inbox,
  User,
  Users,
  Settings,
  Home,
  Circle,
} from 'react-feather';

import { useAuth } from '../../utility/context/AuthProvider';

const navigation = () => {
  // ** Store Vars
  const { notification } = useAuth();

  return [
    {
      id: 'dashboards',
      title: 'แดชบอร์ด',
      icon: <PieChart size={20} />,
      navLink: '/dashboard/overall',
      action: 'view',
      resource: 'แดชบอร์ด',
      children: [
        {
          id: 'overall',
          title: 'ภาพรวม',
          icon: <Circle size={12} />,
          navLink: '/dashboard/overall',
          action: 'view',
          resource: 'แดชบอร์ด',
        },
        {
          id: 'appointmentreport',
          title: 'รายงานนัดหมาย',
          icon: <Circle size={12} />,
          navLink: '/dashboard/appointmentreport',
          action: 'view',
          resource: 'แดชบอร์ด',
        },
        {
          id: 'doctorreport',
          title: 'รายงานแพทย์',
          icon: <Circle size={12} />,
          navLink: '/dashboard/doctorreport',
          action: 'view',
          resource: 'แดชบอร์ด',
        },
        {
          id: 'casereport',
          title: 'รายงานคนไข้',
          icon: <Circle size={12} />,
          navLink: '/dashboard/casereport',
          action: 'view',
          resource: 'แดชบอร์ด',
        },
      ],
    },
    {
      id: 'calendar',
      title: 'Calendar',
      icon: <Calendar size={20} />,
      navLink: '/calendar',
      action: 'view',
      resource: 'Calendar',
    },
    {
      id: 'schedules',
      title: 'หาแพทย์ลงตาราง',
      icon: <Compass size={20} />,
      navLink: '/clinic-schedule/posts',
      action: 'view',
      resource: 'หาแพทย์ลงตาราง',
      children: [
        {
          id: 'posts',
          title: 'โพสต์ของฉัน',
          icon: <Circle size={12} />,
          navLink: '/clinic-schedule/posts',
          action: 'view',
          resource: 'หาแพทย์ลงตาราง',
        },
        {
          id: 'approvalrequest',
          title: 'อนุมัติคำขอ',
          icon: <Circle size={12} />,
          badge: 'custom-nav-badge',
          badgeText: notification?.doctorWaitRequestTotal || '0',
          navLink: '/clinic-schedule/requests',
          action: 'view',
          resource: 'หาแพทย์ลงตาราง',
        },
        {
          id: 'match',
          title: 'ค้นหา',
          icon: <Circle size={12} />,
          navLink: '/clinic-schedule/match',
          action: 'view',
          resource: 'หาแพทย์ลงตาราง',
        },
      ],
    },
    {
      id: 'clinicAppointment',
      title: 'รายการนัดหมาย',
      icon: <FileText size={20} />,
      badge: 'custom-nav-badge',
      badgeText: notification?.appointmentTotal || '0',
      navLink: '/appointments',
      action: 'view',
      resource: 'รายการนัดหมาย',
    },
    {
      id: 'task',
      title: 'งานของฉัน',
      icon: <Inbox size={20} />,
      badge: 'custom-nav-badge',
      badgeText: notification?.customerSupportJobTotal || '0',
      navLink: '/tasks',
      action: 'view',
      resource: 'งานของฉัน',
    },
    {
      id: 'clinic-user',
      title: 'คนไข้ของคลินิก',
      icon: <User size={20} />,
      navLink: '/patients',
      action: 'view',
      resource: 'คนไข้ของคลินิก',
    },
    {
      id: 'friends',
      title: 'เพื่อนแพทย์',
      icon: <Users size={20} />,
      navLink: '/clinic-friends/list',
      action: 'view',
      resource: 'เพื่อนแพทย์',
      children: [
        {
          id: 'clinicfriends',
          title: 'เพื่อนทั้งหมด',
          icon: <Circle size={12} />,
          navLink: '/clinic-friends/list',
          action: 'view',
          resource: 'เพื่อนแพทย์',
        },
        {
          id: 'findClinicFriends',
          title: 'ค้นหาแพทย์',
          icon: <Circle size={12} />,
          navLink: '/clinic-friends/find',
          action: 'view',
          resource: 'เพื่อนแพทย์',
        },
      ],
    },
    {
      id: 'setting',
      title: 'ตั้งค่า',
      icon: <Settings size={20} />,
      navLink: '/settings',
      action: 'view',
      resource: 'ตั้งค่า',
    },
    {
      id: 'edit',
      title: 'จัดการคลินิก',
      icon: <Home size={20} />,
      navLink: '/clinics',
      action: 'view',
      resource: 'จัดการคลินิก',
      children: [
        {
          id: 'editClinic',
          title: 'คลินิก',
          icon: <Circle size={12} />,
          navLink: '/clinics/manage',
          action: 'view',
          resource: 'จัดการคลินิก',
        },
        {
          id: 'userManage',
          title: 'จัดการผู้ใช้งาน',
          icon: <Circle size={12} />,
          navLink: '/clinics/members',
          action: 'view',
          resource: 'จัดการคลินิก',
        },
      ],
    },
  ];
};

export default navigation;
