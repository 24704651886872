// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import NavbarUser from './NavbarUser';

// ** Third Party Components
import { Sun, Moon, Menu } from 'react-feather';

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

const NavTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #191a1c;
`;
const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility, navbarTitle } = props;

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />;
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />;
    }
  };

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <ul className='navbar-nav d-xl-none'>
          <NavItem className='mobile-menu me-auto'>
            <NavLink
              className='nav-menu-main menu-toggle hidden-xs is-active'
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className='ficon' />
            </NavLink>
          </NavItem>
        </ul>
        <NavItem className='d-none d-lg-block user-select-none'>
          <div className='nav-link-style'>
            {/* <ThemeToggler /> */}
            <NavTitle>{navbarTitle}</NavTitle>
          </div>
        </NavItem>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
