import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import useIsMounted from '../utility/hooks/useIsMounted';
import Navbar from '../components/layout/Navbar/Index';
import Footer from '../components/layout/Footer/Index';

import './AppLayout.scss';

const AppLayout: FC = () => {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  return (
    <div className='app-layout position-relative'>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default AppLayout;
