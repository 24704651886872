import { FC, PropsWithChildren, Suspense, useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import SpinnerComponent from '@components/spinner/Fallback-spinner';

import { useAuth } from '../../utility/context/AuthProvider';

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, clinicData, currentBranch } = useAuth();
  const location = useLocation();
  if (isAuthenticated && !currentBranch) {
    return <Navigate to={'/profile/edit'} />;
  }
  if (!isAuthenticated && !clinicData[0]) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return <Suspense fallback={<SpinnerComponent />}>{children}</Suspense>;
};

export default PrivateRoute;
