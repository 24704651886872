import logoImage from '../../assets/images/logo/logo.svg';
import deleteIconImage from '../../assets/images/icon/delete-icon.svg';
import defaultUploadImage from '../../assets/svg/defaultUploadImage.svg';
import { GenderIdType, OptionInterface } from '../../utility/types';

export const LOGO = logoImage;

export const DELETE_ICON = deleteIconImage;
export const DEFAULT_UPLOAD_IMG = defaultUploadImage;
export const IS_OWNER = 'Owner';
export const IS_STANDARD = 'Standard';
export const GENDER: Record<GenderIdType, string> = {
  1: 'ชาย',
  2: 'หญิง',
  3: 'ไม่ระบุ',
};

export const FRIEND_STATUS = {
  FRIEND: 'friend',
  REQUEST_FRIEND: 'requestFriend',
  WAIT_APPROVE: 'waitApprove',
  NOT_FRIEND: 'notFriend',
};

export const FETCH_FAILED = 'พบข้อผิดพลาดในการเรียกดูข้อมูล';
export const REQUIRED_FIELD = 'โปรดระบุ';

export const THAI_ENG_CHAR_REGEX = /[a-zA-Z | \u0E00-\u0E3A\u0E40-\u0E4E]/;
export const NUMBER_CHAR_REGEX = /[0-9]/;
export const THAI_ENG_NUMBER_CHAR_REGEX =
  /[a-zA-Z0-9 | \u0E00-\u0E3A\u0E40-\u0E4E]/;
export const THAI_ENG_CHAR_NO_SPACE_REGEX = /[A-Za-z]|[ก-ๆ]/;
export const ENG_NUMBER_CHAR_REGEX = /^[a-zA-Z0-9]+$/;

export const DEFAULT_OPTION: OptionInterface = {
  value: '',
  label: 'ทั้งหมด',
};
export const MAX_SIZE = 5242880;
export const PERMISSTION = [
  {
    id: 'c8bef0ad-a88e-4a42-94a9-4f4973645e82',
    name: 'แดชบอร์ด',
  },
  {
    id: '923e94c7-9b79-41e0-bd01-2e7b0e0db039',
    name: 'Calendar',
  },
  {
    id: '2040486b-6491-4a2b-b142-4935589f630d',
    name: 'หาแพทย์ลงตาราง',
  },
  {
    id: '981145c6-ad8f-4727-b6b7-fc10938c4229',
    name: 'รายการนัดหมาย',
  },
  {
    id: 'd9c183bc-4a6c-41f4-b2cb-c0d4b4b8ddce',
    name: 'คนไข้ของคลินิก',
  },
  {
    id: '0837893d-098a-41ba-85d1-18a04762bdd7',
    name: 'เพื่อนแพทย์',
  },
  {
    id: 'dbe8b103-01d8-4217-8bad-394c144ffd7f',
    name: 'อื่นๆ',
  },
];
export const NAVIGATION_LIST = [
  {
    id: 'dashboards',
    title: 'แดชบอร์ด',
    navLink: '/dashboard/overall',
  },
  {
    id: 'calendar',
    title: 'Calendar',
    navLink: '/calendar',
  },
  {
    id: 'schedules',
    title: 'หาแพทย์ลงตาราง',
    navLink: '/clinic-schedule/posts',
  },
  {
    id: 'clinicAppointment',
    title: 'รายการนัดหมาย',
    navLink: '/appointments',
  },
  {
    id: 'task',
    title: 'งานของฉัน',
    navLink: '/tasks',
  },
  {
    id: 'clinic-user',
    title: 'คนไข้ของคลินิก',
    navLink: '/patients',
  },
  {
    id: 'friends',
    title: 'เพื่อนแพทย์',
    navLink: '/clinic-friends/list',
  },
  {
    id: 'setting',
    title: 'ตั้งค่า',
    navLink: '/settings',
  },
  {
    id: 'edit',
    title: 'จัดการคลินิก',
    navLink: '/clinics',
  },
];
export const CLEAVE_NUMBER_OPTIONS = {
  numeral: true,
};
export const CLEAVE_NUMBER_ZERO_PRECISION_OPTIONS = {
  numeral: true,
  numeralDecimalScale: 0,
};
export const CLEAVE_PHONE_OPTIONS = { phone: true, phoneRegionCode: 'TH' };
export const CLEAVE_ID_CARD_OPTIONS = {
  numericOnly: true,
  blocks: [1, 4, 5, 2, 1],
};

export const IMAGE_FILE_SIZE = 5242880;
