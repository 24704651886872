// ** React Imports
import { NavLink } from 'react-router-dom';

// ** Third Party Components
import classnames from 'classnames';

// ** Reactstrap Imports
import { Badge } from 'reactstrap';

const VerticalNavMenuLink = ({ item, setActiveItem, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink;

  // ** Hooks
  return (
    <li
      className={classnames({
        'nav-item': !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem,
      })}
      onClick={() => setActiveItem(item.title)}
    >
      <LinkTag
        className='d-flex align-items-center'
        target={item.newTab ? '_blank' : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || '/',
            }
          : {
              to: item.navLink || '/',
              className: ({ isActive }) => {
                return `d-flex align-items-center ${
                  isActive && !item.disabled ? 'active' : ''
                }`;
              },
            })}
        onClick={(e) => {
          if (
            item.navLink.length === 0 ||
            item.navLink === '#' ||
            item.disabled === true
          ) {
            e.preventDefault();
          }
        }}
      >
        {item.icon}
        <span className='menu-item text-truncate'>{item.title}</span>

        {item.badge && item.badgeText ? (
          <Badge className='ms-auto' color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  );
};

export default VerticalNavMenuLink;
