// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
import { getCookie, removeCookie, setCookie } from '../utility/Utils';

// ** UseJWT import to get config
import useJwt from '../auth/jwt/useJwt';

const config = useJwt.jwtConfig;
const initialUser = () => {
  const item = getCookie('userData');

  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      setCookie(config.storageTokenKeyName, action.payload.accessToken);
      setCookie(config.storageRefreshTokenKeyName, action.payload.refreshToken);
    },
    handleLogout: (state) => {
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      state.userData = {};

      removeCookie(config.storageTokenKeyName);
      removeCookie(config.storageRefreshTokenKeyName);
      removeCookie('currentBranch');
      removeCookie('clinicData');
    },
    handleSetProfile: (state, action) => {
      state.userData = action.payload;
      setCookie('userData', JSON.stringify(action.payload));
    },
  },
});

export const { handleLogin, handleLogout, handleSetProfile } =
  authSlice.actions;

export default authSlice.reducer;
