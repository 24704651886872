// ** React Imports
import { Suspense, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { useAuth } from '../../../utility/context/AuthProvider';
import { getCookie } from '../../../utility/Utils';

const RouteIsNoSprinter = [
  '/doctor-privacy-policy',
  '/doctor-term-and-condition',
  '/patient-privacy-policy',
  '/patient-term-and-condition',
  '/doctor-aboutus',
  '/patient-aboutus',
];
const PublicRoute = ({ children, route }) => {
  const { userData, currentBranch, isAuthenticated } = useAuth();

  if (route) {
    if (userData && isAuthenticated && currentBranch) {
      const permissions =
        userData.clinicCustomerSupport.clinicCustomerSupportRole
          .clinicCustomerSupportRolePermissions;
      const permission = permissions.map(
        (item) => item.clinicCustomerSupportPermission.name
      );
      return <Navigate to={getHomeRouteForLoggedInUser(permission[0])} />;
    }
    if (RouteIsNoSprinter.includes(route.path)) {
      return <Suspense fallback={null}>{children}</Suspense>;
    }
  }
  return <Suspense fallback={<SpinnerComponent />}>{children}</Suspense>;
};

export default PublicRoute;
