// ** Dropdowns Imports
import BranchDropdown from './BranchDropdown';
import UserDropdown from './UserDropdown';

const NavbarUser = () => {
  return (
    <ul className='nav navbar-nav align-items-center ms-auto'>
      <BranchDropdown />
      <UserDropdown />
    </ul>
  );
};
export default NavbarUser;
