import { FC } from 'react';
import Logo from '../../common/Logo/Index';
import FACEBOOK_ICON from '../../../assets/images/footer/facebook_icon.svg';
import LINE_ICON from '../../../assets/images/footer/line_icon.svg';
import { HashLink } from 'react-router-hash-link';

import './Footer.scss';

interface NavItemInterface {
  label: string;
  hashTo: string;
  newTab?: boolean;
}

const NAV_ITEMS: Array<NavItemInterface> = [
  {
    label: 'หน้าแรก',
    hashTo: '/home#',
  },
  {
    label: 'บริการ',
    hashTo: '/home#services',
  },

  {
    label: 'ข้อตกลงการใช้บริการ',
    hashTo: '/terms#',
    newTab: true,
  },
  {
    label: 'ราคา',
    hashTo: '/home#package',
  },
  {
    label: 'ติดต่อเรา',
    hashTo: '/home#contact_us',
  },

  {
    label: 'นโยบายความเป็นส่วนตัว',
    hashTo: '/privacy#',
    newTab: true,
  },
];

const Footer: FC = () => {
  return (
    <footer>
      <section className='footer'>
        <div className='container-lg'>
          <div className='row'>
            <div className='footer_content col-12 col-lg-3'>
              <Logo width={198} />
              <p>
                ค้นหาและนัดหมายออนไลน์ ลงเวลางานพร้อมจัดการตารางนัด อย่างมีระบบ
              </p>
            </div>
            <div className='col-12 col-lg-6'>
              <h3 className='footer_headline'>เมนูหลัก</h3>
              <ul className='footer_nav row'>
                {NAV_ITEMS.map((nav) => (
                  <li
                    key={nav.label}
                    className='col-12 col-md-4 text-center text-lg-start'
                  >
                    {nav.newTab ? (
                      <a href={nav.hashTo} rel='noreferrer' target='_blank'>
                        {nav.label}
                      </a>
                    ) : (
                      <HashLink smooth to={nav.hashTo}>
                        {nav.label}
                      </HashLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col-12 col-lg-3 text-lg-start text-center'>
              <h3 className='footer_headline'>ติดต่อเรา</h3>
              <p className='footer_contact'>โทร : +66 96 148 3688</p>
              <p className='footer_contact_email'>
                อีเมล : <a href='mailto:info@dunudd.com'>info@dunudd.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='copy-right border-top'>
        <div className='container-lg d-flex justify-content-between align-items-center'>
          <span>© {new Date().getFullYear()}. All Rights Reserved</span>
          <div className='social-contact'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.facebook.com/dunudd'
            >
              <button>
                <img src={FACEBOOK_ICON} alt='facebook icon' />
              </button>
            </a>
            <button>
              <img src={LINE_ICON} alt='line icon' />
            </button>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
