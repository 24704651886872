// ** React Imports
import { Fragment, lazy } from 'react';
import { Navigate } from 'react-router-dom';
// ** Layouts
import BlankLayout from '@layouts/BlankLayout';
import VerticalLayout from '@src/layouts/VerticalLayout';
import HorizontalLayout from '@src/layouts/HorizontalLayout';
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper';
import AppLayout from '../../layouts/AppLayout';

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute';
import PrivateRoute from '../../components/layout/PrivateRoute';

// ** Utils
import { isObjEmpty } from '@utils';

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
  app: <AppLayout />,
};

// ** Document title
const TemplateTitle = '%s - NUDD';

// ** Default Route
const DefaultRoute = '/home';

const HomePage = lazy(() => import('../../views/HomePage.tsx'));
const LoginPage = lazy(() =>
  import('../../views/authentication/LoginPage.tsx')
);
const ForgotPasswordPage = lazy(() =>
  import('../../views/authentication/ForgotPasswordPage.tsx')
);
const ResetPasswordPage = lazy(() =>
  import('../../views/authentication/ResetPasswordPage.tsx')
);
const RegisterPage = lazy(() =>
  import('../../views/authentication/RegisterPage.tsx')
);

const TermsPage = lazy(() => import('../../views/terms/TermsPage.tsx'));
const PrivacyPage = lazy(() => import('../../views/privacy/PrivacyPage.tsx'));

// ** Vertical Layout
const OverallPage = lazy(() => import('../../views/dashboard/OverallPage.tsx'));
const AppointmentReportPage = lazy(() =>
  import('../../views/dashboard/AppointmentReportPage.tsx')
);
const DoctorReportPage = lazy(() =>
  import('../../views/dashboard/DoctorReportPage.tsx')
);
const CaseReportPage = lazy(() =>
  import('../../views/dashboard/CaseReportPage.tsx')
);
const CalendarPage = lazy(() =>
  import('../../views/calendar/CalendarPage.tsx')
);
const UserPostPage = lazy(() =>
  import('../../views/clinic-schedule/UserPostPage.tsx')
);
const ApproveRequestPage = lazy(() =>
  import('../../views/clinic-schedule/ApproveRequestPage.tsx')
);
const MatchDoctorPage = lazy(() =>
  import('../../views/clinic-schedule/MatchDoctorPage.tsx')
);
const EditPostPage = lazy(() =>
  import('../../views/clinic-schedule/EditPostPage.tsx')
);
const AppointmentsPage = lazy(() =>
  import('../../views/appointment/AppointmentsPage.tsx')
);
const TasksPage = lazy(() => import('../../views/task/TasksPage.tsx'));
const PatientsPage = lazy(() => import('../../views/patient/PatientsPage.tsx'));
const PatientDetailPage = lazy(() =>
  import('../../views/patient/PatientDetailPage.tsx')
);
const FriendPage = lazy(() => import('../../views/friend/FriendsPage.tsx'));
const FindClinicFriend = lazy(() =>
  import('../../views/friend/FindClinicFriend.tsx')
);
const SettingPage = lazy(() => import('../../views/setting/SettingPage.tsx'));
const ClinicManagePage = lazy(() =>
  import('../../views/clinic-manage/ClinicManagePage.tsx')
);

const ClinicUserManage = lazy(() =>
  import('../..//views/clinic-manage/ClinicUserManage.tsx')
);

const ClinicUserCreate = lazy(() =>
  import('../../views/clinic-manage/ClinicUserCreate.tsx')
);

const ClinicUserUpdate = lazy(() =>
  import('../../views/clinic-manage/ClinicUserUpdate.tsx')
);

const BranchInfoPage = lazy(() =>
  import('../../views/clinic-manage/branch/BranchInfoPage')
);

const AddClinicInfoPage = lazy(() =>
  import('../../views/AddClinicInfoPage.tsx')
);

const ProfilePage = lazy(() => import('../../views/profile/ProfilePage.tsx'));
const PrivacyPolicyPage = lazy(() => import('../../views/PrivacyPolicyPage'));
const TermConditionPage = lazy(() => import('../../views/TermConditionPage'));
const DoctorPrivacyPolicyPage = lazy(() =>
  import('../../views/PivacyPolicyDoctorPage')
);
const DoctorTermConditionPage = lazy(() =>
  import('../../views/TermConditionDoctorPage')
);
const DoctorAboutUs = lazy(() =>
  import('../../views/aboutus/DoctorAboutUsPage')
);
const PatientAboutUs = lazy(() =>
  import('../../views/aboutus/PatientAboutUsPage')
);
const AboutUsPage = lazy(() => import('../../views/AboutUsPage'));

const RegisterOnsitePage = lazy(() =>
  import('../../views/register-onsite/RegisterOnsitePage')
);

// ** Merge Routes
const Routes = [
  {
    path: '/',
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/home',
    element: <HomePage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/login',
    element: <LoginPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/register',
    element: <RegisterPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/profile/edit',
    element: <AddClinicInfoPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/patient-privacy-policy',
    element: <PrivacyPolicyPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/patient-term-and-condition',
    element: <TermConditionPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/doctor-privacy-policy',
    element: <DoctorPrivacyPolicyPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/doctor-term-and-condition',
    element: <DoctorTermConditionPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/doctor-aboutus',
    element: <DoctorAboutUs />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/patient-aboutus',
    element: <PatientAboutUs />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/about-us',
    element: <AboutUsPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/terms',
    element: <TermsPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/privacy',
    element: <PrivacyPage />,
    meta: {
      layout: 'app',
      publicRoute: true,
    },
  },
  {
    path: '/register-onsite',
    element: <RegisterOnsitePage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
  {
    path: '/calendar',
    element: <CalendarPage />,
  },
  {
    path: '/dashboard/overall',
    element: <OverallPage />,
  },
  {
    path: '/dashboard/appointmentreport',
    element: <AppointmentReportPage />,
  },
  {
    path: '/dashboard/doctorreport',
    element: <DoctorReportPage />,
  },
  {
    path: '/dashboard/casereport',
    element: <CaseReportPage />,
  },
  {
    path: '/clinic-schedule/posts',
    element: <UserPostPage />,
  },
  {
    path: '/clinic-schedule/requests',
    element: <ApproveRequestPage />,
  },
  {
    path: '/clinic-schedule/match',
    element: <MatchDoctorPage />,
  },
  {
    path: '/clinic-schedule/posts/:postId/edits',
    element: <EditPostPage />,
  },
  {
    path: '/appointments',
    element: <AppointmentsPage />,
  },
  {
    path: '/tasks',
    element: <TasksPage />,
  },
  {
    path: '/patients',
    element: <PatientsPage />,
  },
  {
    path: '/patients/:patientId',
    element: <PatientDetailPage />,
  },
  {
    path: '/clinic-friends/list',
    element: <FriendPage />,
  },
  {
    path: '/clinic-friends/find',
    element: <FindClinicFriend />,
  },
  {
    path: '/settings',
    element: <SettingPage />,
  },
  {
    path: '/clinics/manage',
    element: <ClinicManagePage />,
  },
  {
    path: '/clinics/manage/:clinicId/branches/:branchId',
    element: <BranchInfoPage />,
  },
  {
    path: '/clinics/manage/:clinicId/branches/:branchId/treatment',
    element: <ClinicManagePage />,
  },
  {
    path: '/clinics/manage/:clinicId/branches/:branchId/commission',
    element: <ClinicManagePage />,
  },
  {
    path: '/clinics/manage/:clinicId/branches/:branchId/qr-code',
    element: <ClinicManagePage />,
  },
  {
    path: '/clinics/members',
    element: <ClinicUserManage />,
  },
  {
    path: '/clinics/members/create',
    element: <ClinicUserCreate />,
  },
  {
    path: '/clinics/members/:memberId/edit',
    element: <ClinicUserUpdate />,
  },
  {
    path: '/me',
    element: <ProfilePage />,
  },
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' || route.meta.layout === 'app'
            ? (isBlank = true)
            : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment;
          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical';
  const layouts = ['vertical', 'horizontal', 'blank', 'app'];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { DefaultRoute, TemplateTitle, Routes, getRoutes };
