// ** Auth Endpoints
export default {
  loginEndpoint: '/auth/clinic/login',
  refreshEndpoint: '/auth/clinic/refresh',
  profileEndpoint: '/auth/clinic/me',
  forgetEndpoint: '/auth/clinic/forget-password',
  changePasswordEndpoint: '/auth/clinic/change-password',
  resetPasswordEndpoint: '/auth/clinic/reset-password',
  registerEndpoint: '/public/clinic',
  logoutEndpoint: '/jwt/logout',
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
};
