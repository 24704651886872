// ** React Imports
import { Link, useNavigate } from 'react-router-dom';
import { memo } from 'react';
// ** Custom Components
import Avatar from '@components/avatar';

// ** Third Party Components
import { User, Lock, Power } from 'react-feather';

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png';

import useAlert from '../../../../utility/hooks/useAlert';
import { useAuth } from '../../../../utility/context/AuthProvider';
import ChangePassowordModal from '../../../../components/authentication/ChangePasswordModal';
import useDisclosure from '../../../../utility/hooks/useDisclosure';

const UserDropdown = () => {
  const navigate = useNavigate();
  const { userData, logout } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleUserLogout = async () => {
    const response = await useAlert().confirmCancel('ออกจากระบบ');
    if (response) {
      logout();
      navigate('/');
    }
  };

  return (
    <>
      <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
        <DropdownToggle
          href='/'
          tag='a'
          className='nav-link dropdown-user-link'
          onClick={(e) => e.preventDefault()}
        >
          <Avatar
            img={userData?.profileImage?.url || defaultAvatar}
            imgHeight='40'
            imgWidth='40'
            status='online'
          />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag={Link} to='/me'>
            <Lock size={14} className='me-75' />
            <span className='align-middle'>ตั้งค่าบัญชี</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={onOpen}>
            <User size={14} className='me-75' />
            <span className='align-middle'>เปลี่ยนรหัสผ่าน</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} onClick={handleUserLogout}>
            <Power size={14} className='me-75' color='red' />
            <span className='align-middle text-danger'>ออกจากระบบ</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ChangePassowordModal
        isOpen={isOpen}
        onClosed={onClose}
        onSubmited={logout}
      />
    </>
  );
};

export default memo(UserDropdown);
