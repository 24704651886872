import jwtDefaultConfig from './jwtDefaultConfig';
import axios from './axios';
import {
  RegisterPropInterface,
  UpdateProfileInterface,
} from '../utility/types/auth';

export interface UserAuthInterface {
  email: string;
  password: string;
}
export interface PasswordInterface {
  password: string;
}

export interface ChangePasswordInterface {
  oldPassword: string;
  newPassword: string;
}
const authServices = {
  login: (args: UserAuthInterface) =>
    axios.post(jwtDefaultConfig.loginEndpoint, args),
  createClinic: (args: RegisterPropInterface) =>
    axios.post(jwtDefaultConfig.registerEndpoint, args),
  forgetPassword: (email: string) =>
    axios.post(jwtDefaultConfig.forgetEndpoint, { email }),
  resetPassword: (password: string) =>
    axios.post(jwtDefaultConfig.resetPasswordEndpoint, { password }),
  changePassword: (payload: ChangePasswordInterface) =>
    axios.patch(jwtDefaultConfig.changePasswordEndpoint, payload),
  getProfile: () => axios.get(jwtDefaultConfig.profileEndpoint),
  getNotification: (clinicId: string, branchId: string) =>
    axios.get(`/clinic/${clinicId}/branches/${branchId}/notification`),
  updateProfile: (data: UpdateProfileInterface) =>
    axios.patch(jwtDefaultConfig.profileEndpoint, data),
  deleteClinic: (clinicId: string, payload: PasswordInterface) =>
    axios.patch(`/clinic/${clinicId}/delete`, payload),
};
export default authServices;
