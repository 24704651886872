// Input.tsx file
import { forwardRef } from 'react';

// ** Custom Components
import InputPassword from '@components/input-password-toggle';
import styled from 'styled-components';
import { InputProps } from 'reactstrap';

const CustomInputPassword = styled(InputPassword)`
  ::-ms-reveal {
    display: none;
  }
`;
const WrappedPasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => <CustomInputPassword innerRef={ref} {...props} />
);

WrappedPasswordInput.displayName = 'WrappedPasswordInput';
export default WrappedPasswordInput;
