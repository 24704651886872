import { FC } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { LOGO } from '../../../utility/constants';

import './Logo.scss';

interface LogoProps {
  width?: number;
  className?: string;
}

const Logo: FC<LogoProps> = ({ width, className }) => {
  return (
    <HashLink to={'/home#'}>
      <span
        className={`app-logo ${className}`}
        style={{
          width: width + 'px',
        }}
      >
        <img src={LOGO} alt='logo' />
      </span>
    </HashLink>
  );
};

Logo.defaultProps = {
  width: 136,
  className: '',
};

export default Logo;
