// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '../services/axios';

export const getStatus = createAsyncThunk(
  'verticalStatus/getStatus',
  async (clinicInfo) => {
    const { data } = await axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.currentBranchId}/notification`
    );
    return { status: data.data.item };
  }
);

export const verticalStatusSlice = createSlice({
  name: 'verticalStatus',
  initialState: {
    status: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.status = action.payload.status;
    });
  },
});

export default verticalStatusSlice.reducer;
