import { FC, Fragment } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { Check } from 'react-feather';
import { useAuth } from '../../../../utility/context/AuthProvider';
const CustomDropDownItem = styled(DropdownItem)`
  width: 100% !important;
  color: $primary !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  padding: 0.7rem 0.5rem !important;

  // prevent select text
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .item-icon {
    margin-left: 1.4rem;
  }
  .active-branch {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #004a85;
  }
`;

const BranchDropdown: FC = () => {
  const { changeClinicBranch, currentBranch, branchesData } = useAuth();

  if (!currentBranch || !branchesData) {
    return (
      <div className='d-flex justify-content-center py-2'>
        <Spinner color='primary' />
      </div>
    );
  }

  return (
    <UncontrolledDropdown tag='li' className='nav-item'>
      <DropdownToggle color='flat-primary' caret className=''>
        {currentBranch?.name ?? ''}
      </DropdownToggle>
      <DropdownMenu start='true'>
        {branchesData.map((item, index, { length }) => (
          <Fragment key={item.id}>
            <CustomDropDownItem onClick={() => changeClinicBranch(item.id)}>
              {item.id === currentBranch?.id ? (
                <>
                  <span className='active-branch'> {item.name || '-'}</span>
                  <Check className='item-icon' size={13} color='#004a85' />
                </>
              ) : (
                item.name
              )}
            </CustomDropDownItem>
            {index + 1 !== length && <DropdownItem divider className='m-0' />}
          </Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default BranchDropdown;
