import axios from 'axios';
import { getCookie, removeCookie, setCookie } from '../utility/Utils';
import jwtDefaultConfig from './jwtDefaultConfig';

const jwtConfig = { ...jwtDefaultConfig };

const RESET_PASSWORD_URL = '/auth/clinic/reset-password';
const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL + '/v1',
});
export const publicInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL + '/v1/public',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// ** Request Interceptor
instance.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage
    const accessToken = getToken();
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// ** For Refreshing Token
var subscribers = [];

// ** Add request/response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: originalRequest, response } = error;

    if (response && response.status === 403) {
      onRemoveToken();
    }
    if (response && response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const res = await refreshToken();
          if (res.status === 200) {
            originalRequest._retry = false;
            setToken(res.data.data.item.accessToken);
            originalRequest.headers.Authorization = `${jwtConfig.tokenType} ${res.data.data.item.accessToken}`;
            return instance(originalRequest);
          }
        } catch (error) {
          if (
            error.response.status === 401 &&
            !originalRequest.url !== RESET_PASSWORD_URL
          ) {
            onRemoveToken();
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

function onRemoveToken() {
  removeCookie('accessToken');
  removeCookie('refreshToken');
  removeCookie('currentBranch');
  removeCookie('clinicData');
  window.location.reload();
}
function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function getToken() {
  return getCookie(jwtConfig.storageTokenKeyName);
}

function getRefreshToken() {
  return getCookie(jwtConfig.storageRefreshTokenKeyName);
}

function setToken(value) {
  setCookie(jwtConfig.storageTokenKeyName, value);
}

function setRefreshToken(value) {
  setCookie(jwtConfig.storageRefreshTokenKeyName, value);
}

function refreshToken() {
  return axios.post(
    process.env.REACT_APP_SERVER_BASE_URL + '/v1' + jwtConfig.refreshEndpoint,
    {
      refreshToken: getRefreshToken(),
    }
  );
}
export default instance;
